import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Score from '../score/Score';
import { SCAN_SANDBOX_STATUS } from '@mdc/constants';
import { useTranslation } from 'react-i18next';

import './SandboxScore.scss';

const SANDBOX_MAX_SCORE = 100;

const SandboxScore = ({ resultsLoading, scanning, fileImage, sandboxResults, category, hasButton, link, buttonText, onButtonClick, buttonDisabled, isFileSupport }) => {
    const { t, ready } = useTranslation();
    const sandboxScanProgress = sandboxResults?.progress ?? null;
    const sandboxScanProgressStatus = sandboxResults?.progressStatus ?? null;

    const getSandboxProcessText = (status, sandboxScanProgress, sandboxScanProgressStatus) => {
        if (status === SCAN_SANDBOX_STATUS.loading) {
            return t('Loading');
        } else if (status === SCAN_SANDBOX_STATUS.processing) {
            if (sandboxScanProgress) {
                return t('Processing [{{ sandboxScanProgress }}%]', { sandboxScanProgress });
            }

            return sandboxScanProgressStatus === SCAN_SANDBOX_STATUS.scheduled ? t('Scheduled') : t('Processing');
        }

        return '';
    };

    const getSandboxDetectionText = {
        '-1': 'benign',
        '0': 'unknown',
        '5': 'unknown',
        '10': 'informational',
        '15': 'informational',
        '20': 'informational',
        '25': 'informational',
        '30': 'informational',
        '35': 'informational',
        '40': 'suspicious',
        '45': 'suspicious',
        '50': 'suspicious',
        '55': 'suspicious',
        '60': 'suspicious',
        '65': 'suspicious',
        '70': 'likelyMalicious',
        '75': 'likelyMalicious',
        '80': 'likelyMalicious',
        '85': 'likelyMalicious',
        '90': 'malicious',
        '95': 'malicious',
        '100': 'malicious'
    };

    const getSandboxInfoText = (sandboxResults, hasButton, sandboxScoreProcessed) => {
        if (sandboxResults?.needEmailSubmit) {
            return t('Blocked');
        } else if (hasButton && !isFileSupport) {
            return t('Filetype is not supported');
        } else if (hasButton && (!sandboxResults?.results || sandboxResults?.results === 'Aborted' || isNaN(sandboxScoreProcessed))) {
            return t('No dynamic analysis performed');
        } else if (sandboxResults?.results?.infected && sandboxScoreProcessed >= 10 && sandboxScoreProcessed <= 30) {
            return t('Informational activity detected');
        } else if (sandboxResults?.results?.infected && sandboxScoreProcessed >= 40 && sandboxScoreProcessed <= 60) {
            return t('Suspicious activity detected');
        } else if (sandboxResults?.results?.infected && sandboxScoreProcessed >= 70 && sandboxScoreProcessed <= 80) {
            return t('Threats Detected');
        } else if (sandboxResults?.results?.infected && sandboxScoreProcessed >= 90 && sandboxScoreProcessed <= 100) {
            return t('Threats Detected');
        }
        return t('No suspicious activity detected');
    };

    const scoreSandboxDom = useMemo(() => {
        const sandboxIsProcessing = resultsLoading || !!scanning || !sandboxResults || sandboxResults.status === SCAN_SANDBOX_STATUS.loading || sandboxResults.status === SCAN_SANDBOX_STATUS.processing;
        const sandboxScoreProcessed = sandboxResults?.results?.score;

        const sandboxProcessText = !sandboxResults ? t('Waiting for Sandbox') : getSandboxProcessText(sandboxResults.status, sandboxScanProgress, sandboxScanProgressStatus);
        const sandboxDetection = sandboxResults?.status === SCAN_SANDBOX_STATUS.error ? 'detected' : getSandboxDetectionText[sandboxScoreProcessed];
        const sandboxInfo = sandboxResults?.status === SCAN_SANDBOX_STATUS.error ? sandboxResults.message : getSandboxInfoText(sandboxResults, hasButton, sandboxScoreProcessed);

        const SANDBOX_BADGE_MESSAGE = [
            { minScore: -1, maxScore: -0.1, color: 'benign', message: t('Benign') },
            { minScore: 0, maxScore: 9, color: 'unknown', message: t('Unknown') },
            { minScore: 10, maxScore: 39, color: 'informational', message: t('Informational') },
            { minScore: 40, maxScore: 69, color: 'suspicious', message: t('Suspicious') },
            { minScore: 70, maxScore: 89, color: 'likelyMalicious', message: t('Likely Malicious') },
            { minScore: 90, maxScore: 100, color: 'malicious', message: t('Malicious') },
        ];

        const sandboxBadge = SANDBOX_BADGE_MESSAGE.filter((entry) => sandboxScoreProcessed >= entry.minScore && sandboxScoreProcessed <= entry.maxScore)[0] || undefined;

        return <Score
            category={category}
            info={sandboxInfo}
            detection={sandboxDetection}
            score={sandboxScoreProcessed}
            numberOfEngines={SANDBOX_MAX_SCORE}
            link={link}
            badge={sandboxBadge?.message}
            buttonText={buttonText}
            onButtonClick={onButtonClick}
            buttonDisabled={buttonDisabled}
            processImgSrc={fileImage?.processing?.publicURL}
            isProcessing={sandboxIsProcessing}
            processingText={sandboxProcessText}
            badgeColor={sandboxBadge?.color}
            scoreColor={sandboxBadge?.color}
            hasButton={hasButton}
            isPercentage={true}
        />;
    }, [resultsLoading, scanning, sandboxResults, sandboxScanProgress, sandboxScanProgressStatus, category, t]);

    if (!ready) {
        return null;
    }

    return <>
        {scoreSandboxDom}
    </>;
};

SandboxScore.propTypes = {
    resultsLoading: PropTypes.bool,
    scanning: PropTypes.string,
    fileImage: PropTypes.object.isRequired,
    sandboxResults: PropTypes.object,
    category: PropTypes.string,
    hasButton: PropTypes.bool,
    link: PropTypes.string,
    buttonText: PropTypes.any,
    onButtonClick: PropTypes.func,
    buttonDisabled: PropTypes.bool,
    isFileSupport: PropTypes.any
};

SandboxScore.defaultProps = {
    hasButton: false
};

export default SandboxScore;
