import { useState, useEffect } from 'react';
import './userInfoBar.scss';
import { Row, Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { placeholders } from '@mdc/ui';

const UserInfoBar = (props) => {
    const [nickname, setNickname] = useState(props.nickname);
    const [readOnly, setReadOnly] = useState(true);

    const { UserInfoPlaceholder } = placeholders;

    useEffect(() => {
        setNickname(props.nickname);
    }, [props.nickname]);

    const handleNickNameChange = async () => {
        setReadOnly(!readOnly);
        if (nickname && (nickname !== props.nickname)) {
            try {
                const response = await props.updateNickname(nickname);
                if (!response) {
                    setNickname(props.nickname);
                }
            } catch (error) {
                setNickname(props.nickname);
            }
        } else {
            setNickname(props.nickname);
        }
    };

    const handleNickNameChangeKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleNickNameChange();
        }
    };

    const handleChange = (event) => {
        setNickname(event.target.value);
    };

    const handleSubmit = (event) => {
        event && event.preventDefault();
        handleNickNameChange();

    };

    const handleEditButtonClick = () => {
        setReadOnly(!readOnly);
    };

    const handleEditButtonKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleEditButtonClick();
        }
    };

    const nicknameTextcomponent = (
        <>
            <div>
                {nickname}
            </div>
            <button
                className="icon-edit bar-check-icon editButton"
                onClick={handleEditButtonClick}
                onKeyDown={handleEditButtonKeyDown}
                tabIndex="0"
            />
        </>
    );
    const nicknameInputcomponent = (
        <Form inline className="nicknameForm pull-right" onSubmit={handleSubmit}>
            <Form.Control type="text" readOnly={readOnly} value={nickname} onChange={handleChange} maxLength="20" />
            <button className="icon-checkmark saveButton" onClick={handleNickNameChange} tabIndex="0" onKeyDown={handleNickNameChangeKeyDown} />
        </Form>
    );

    return (
        <Row className="userInfoBar">
            {!props.user && <Col xs={12}><UserInfoPlaceholder /></Col>}
            {props.user && <>
                <Col xs={8} sm={6} lg={3}>
                    <div className="userName" title={props.userName} >
                        <i className="icon-user-check userIcon" />
                        {props.userName}
                    </div>
                </Col>

                <Col xs={4} sm={6} lg={2}>
                    <div className="points">
                        <i className="icon-award-solid" />
                        {props.points}pts
                    </div>
                </Col>

                <Col xs={12} sm={6} lg={3}>
                    <div className="nickname pull-right">
                        {readOnly &&
                            nicknameTextcomponent
                        }

                        {!readOnly &&
                            nicknameInputcomponent
                        }

                    </div>
                </Col>

                <Col xs={12} sm={6} lg={4}>
                    <div className="email">{props.email}</div>
                </Col>
            </>}
        </Row>
    );

};

UserInfoBar.propTypes = {
    userName: PropTypes.string,
    points: PropTypes.number,
    nickname: PropTypes.string,
    email: PropTypes.string,
    updateNickname: PropTypes.func,
    user: PropTypes.object
};
export default UserInfoBar;
